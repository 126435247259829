import React, { useState } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

const { Header } = Layout;

const Navbar = () => {
  const [open, setOpen] = useState(false); // Update variable to open

  const showDrawer = () => {
    setOpen(true); // Update to setOpen
  };

  const onClose = () => {
    setOpen(false); // Update to setOpen
  };

  return (
    <Header style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
      <div className="logo" style={{ float: 'left', color: 'white', fontSize: '20px', marginRight: '20px' }}>
        Galene
      </div>
      <div className="desktop-menu" style={{ display: 'inline-block' }}>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
          <Menu.Item key="2"><Link to="/about">About</Link></Menu.Item>
          <Menu.Item key="3"><Link to="/contact">Contact</Link></Menu.Item>
        </Menu>
      </div>
      <div className="mobile-menu" style={{ display: 'none' }}>
        <Button type="primary" onClick={showDrawer}>
          <MenuOutlined />
        </Button>
        <Drawer
          title="Navigation"
          placement="right"
          onClose={onClose}
          open={open}  
        >
          <Menu mode="vertical">
            <Menu.Item key="1" onClick={onClose}><Link to="/">Home</Link></Menu.Item>
            <Menu.Item key="2" onClick={onClose}><Link to="/about">About</Link></Menu.Item>
            <Menu.Item key="3" onClick={onClose}><Link to="/contact">Contact</Link></Menu.Item>
          </Menu>
        </Drawer>
      </div>
    </Header>
  );
};

export default Navbar;
