// src/components/Layout.js
import React from 'react';
import { Layout } from 'antd';
import Navbar from './Navbar';
import FooterComponent from './FooterComponent';

const { Content } = Layout;

const AppLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Content style={{ padding: '0 50px', marginTop: '64px' }}>
        {children}
      </Content>
      <FooterComponent />
    </Layout>
  );
};

export default AppLayout;
